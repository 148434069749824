<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="ma-0 pa-0">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 426 829">
          <g>
            <image width="426" height="829" xlink:href="/images/map_base.jpg?1"></image>
          </g>

          <g v-if="nowMissionChapter == 1 && nowMissionSection >= 1">
            <g transform="translate(736.731 20) rotate(90)">
              <text transform="translate(10 660.731)" fill="#e2ff00" font-size="58"
                font-family="ShipporiMinchoB1-ExtraBold, Shippori Mincho B1" font-weight="800">
                <tspan x="0" y="0">ワイン風呂</tspan>
                <tspan y="0" fill="#d9e9e5">にある</tspan>
                <tspan fill="#d9e9e5">
                  <tspan x="0" y="58">パネルを見つけろ</tspan>
                </tspan>
              </text>
            </g>
            <g transform="translate(130 304)" class="mark">
              <path
                d="M44.561,22.276A22.281,22.281,0,1,0,8.218,39.56c3.082,3.565,6.506,6.37,7.969,9.224,3.22,6.278,5.576,26.264,6.044,29.229l.042.272.043-.272c.468-2.965,2.824-22.951,6.044-29.229,1.461-2.848,4.872-5.646,7.948-9.2a22.236,22.236,0,0,0,8.252-17.309"
                transform="translate(0 0.001)" fill="#ff00f5" />
              <path d="M63.326,51.052A12.284,12.284,0,1,1,51.042,38.77,12.284,12.284,0,0,1,63.326,51.052"
                transform="translate(-28.767 -28.776)" fill="#ffebff" />
            </g>
          </g>
          <g v-else-if="nowMissionChapter == 2">
            <g transform="translate(736.731 20) rotate(90)">
              <text transform="translate(10 599.964)" fill="#e2ff00" font-size="54"
                font-family="ShipporiMinchoB1-ExtraBold, Shippori Mincho B1" font-weight="800">
                <tspan x="0" y="0">ドクターフィッシュの</tspan>
                <tspan x="0" y="54">水槽</tspan>
                <tspan y="54" fill="#d9e9e5">にある</tspan>
                <tspan fill="#d9e9e5">
                  <tspan x="0" y="108">パネルを見つけろ</tspan>
                </tspan>
              </text>
            </g>
            <g transform="translate(181 258)" class="mark">
              <path
                d="M44.561,22.276A22.281,22.281,0,1,0,8.218,39.56c3.082,3.565,6.506,6.37,7.969,9.224,3.22,6.278,5.576,26.264,6.044,29.229l.042.272.043-.272c.468-2.965,2.824-22.951,6.044-29.229,1.461-2.848,4.872-5.646,7.948-9.2a22.236,22.236,0,0,0,8.252-17.309"
                transform="translate(0 0.001)" fill="#ff00f5" />
              <path d="M63.326,51.052A12.284,12.284,0,1,1,51.042,38.77,12.284,12.284,0,0,1,63.326,51.052"
                transform="translate(-28.767 -28.776)" fill="#ffebff" />
            </g>
          </g>
          <g v-else-if="nowMissionChapter == 3">
            <g transform="translate(736.731 20) rotate(90)">
              <text transform="translate(10 660.731)" fill="#e2ff00" font-size="58"
                font-family="ShipporiMinchoB1-ExtraBold, Shippori Mincho B1" font-weight="800">
                <tspan x="0" y="0">緑茶風呂</tspan>
                <tspan y="0" fill="#d9e9e5">にある</tspan>
                <tspan fill="#d9e9e5">
                  <tspan x="0" y="58">パネルを見つけろ</tspan>
                </tspan>
              </text>
            </g>
            <g transform="translate(199 230)" class="mark">
              <path
                d="M44.561,22.276A22.281,22.281,0,1,0,8.218,39.56c3.082,3.565,6.506,6.37,7.969,9.224,3.22,6.278,5.576,26.264,6.044,29.229l.042.272.043-.272c.468-2.965,2.824-22.951,6.044-29.229,1.461-2.848,4.872-5.646,7.948-9.2a22.236,22.236,0,0,0,8.252-17.309"
                transform="translate(0 0.001)" fill="#ff00f5" />
              <path d="M63.326,51.052A12.284,12.284,0,1,1,51.042,38.77,12.284,12.284,0,0,1,63.326,51.052"
                transform="translate(-28.767 -28.776)" fill="#ffebff" />
            </g>
          </g>
          <g v-else-if="nowMissionChapter == 4">
            <g transform="translate(736.731 20) rotate(90)">
              <text transform="translate(10 660.731)" fill="#e2ff00" font-size="58"
                font-family="ShipporiMinchoB1-ExtraBold, Shippori Mincho B1" font-weight="800">
                <tspan x="0" y="0">コーヒー風呂</tspan>
                <tspan y="0" fill="#d9e9e5">にある</tspan>
                <tspan fill="#d9e9e5">
                  <tspan x="0" y="58">パネルを見つけろ</tspan>
                </tspan>
              </text>
            </g>
            <g transform="translate(254 252)" class="mark">
              <path
                d="M44.561,22.276A22.281,22.281,0,1,0,8.218,39.56c3.082,3.565,6.506,6.37,7.969,9.224,3.22,6.278,5.576,26.264,6.044,29.229l.042.272.043-.272c.468-2.965,2.824-22.951,6.044-29.229,1.461-2.848,4.872-5.646,7.948-9.2a22.236,22.236,0,0,0,8.252-17.309"
                transform="translate(0 0.001)" fill="#ff00f5" />
              <path d="M63.326,51.052A12.284,12.284,0,1,1,51.042,38.77,12.284,12.284,0,0,1,63.326,51.052"
                transform="translate(-28.767 -28.776)" fill="#ffebff" />
            </g>
          </g>

          <g transform="translate(63 652)" @click="goMission()"
            v-if="(nowMissionChapter == 1 && nowMissionSection >= 1) || (nowMissionChapter >= 2 && nowMissionChapter <= 4)">
            <defs>
              <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#ff009c" />
                <stop offset="1" />
              </linearGradient>
              <clipPath id="clip-path">
                <path id="パス_46255" data-name="パス 46255"
                  d="M9.992,28.067a3.216,3.216,0,1,1,3.216,3.217,3.221,3.221,0,0,1-3.216-3.217m264,0a3.217,3.217,0,1,1,3.217,3.217,3.221,3.221,0,0,1-3.217-3.217M21.567.284.354,28.567,21.567,56.851H271.85L293.063,29.07,271.852.284Z"
                  fill="url(#linear-gradient)" />
              </clipPath>
              <clipPath id="clip-path-2">
                <rect id="長方形_1271" data-name="長方形 1271" width="293.417" height="57.134" transform="translate(0 0)"
                  fill="none" />
              </clipPath>
            </defs>
            <g id="グループ_2552" data-name="グループ 2552" transform="translate(-67 -805)">
              <g id="グループ_2078" data-name="グループ 2078">
                <g id="グループ_2077" data-name="グループ 2077">
                  <g id="グループ_2074" data-name="グループ 2074" transform="translate(67 805)">
                    <g id="グループ_2073" data-name="グループ 2073" clip-path="url(#clip-path)">
                      <rect id="長方形_1270" data-name="長方形 1270" width="292.709" height="56.567"
                        transform="translate(0.354 0.284)" fill="url(#linear-gradient)" />
                    </g>
                  </g>
                  <g id="グループ_2076" data-name="グループ 2076" transform="translate(67 805)">
                    <g id="グループ_2075" data-name="グループ 2075" clip-path="url(#clip-path-2)">
                      <path id="パス_46256" data-name="パス 46256"
                        d="M271.709.567l21,28.5-21,27.5h-250l-21-28,21-28Zm5.5,31a3.5,3.5,0,1,0-3.5-3.5,3.5,3.5,0,0,0,3.5,3.5m-264,0a3.5,3.5,0,1,0-3.5-3.5,3.5,3.5,0,0,0,3.5,3.5M271.995,0H21.426l-.17.227-21,28L0,28.567l.256.34,21,28,.17.227H271.989l.171-.223,21-27.5.258-.338-.252-.342-21-28.5Zm5.214,31a2.933,2.933,0,1,1,2.933-2.933A2.937,2.937,0,0,1,277.209,31m-264,0a2.933,2.933,0,1,1,2.933-2.933A2.937,2.937,0,0,1,13.209,31"
                        fill="#d20080" />
                    </g>
                  </g>
                </g>
              </g>
              <text id="パネルを見つけた" transform="translate(214 841)" fill="#fff" font-size="18"
                font-family="ShipporiMinchoB1-Bold, Shippori Mincho B1" font-weight="700" letter-spacing="0.05em">
                <tspan x="-75.15" y="0">パネルを見つけた</tspan>
              </text>
            </g>

          </g>
        </svg>
      </v-col>

    </v-row>

  </v-container>
</template>
<style scoped>
.mark {
  animation: flash 2s linear infinite;
}

@keyframes flash {

  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  95% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}
</style>
<script>

export default {
  data: () => ({
    // nowMissionChapter: 5
  }),
  methods: {
    goMission() {
      // console.log(this.nowMissionChapter);
      if (this.nowMissionSection == 0) {
        this.$store.commit("setMissionSection", 1);
      }
      this.$router.push('/mission' + this.nowMissionChapter);
    }

  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {
  }
};
</script>