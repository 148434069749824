<template>
  <v-container>

    <v-row class="text-center mb-15" v-if="viewMode == 1">
      <v-col cols="12" class="text-center" style="margin-top:80vh;margin-bottom: 20vh;">
        <img src="/images/scrollArrow.svg" />
      </v-col>
      <v-col cols="12" class="text-center ma-0 pa-0">
        <img src="/images/mission4_smartphone.png" width="100%" />
      </v-col>
      <v-col cols="12" class="text-center " style="margin-top:60vh;margin-bottom: 20vh;">
        <img src="/images/mission4_nazo.png" width="70%" />
      </v-col>

      <v-col cols="12" class="text-center qText">
        ユネッサン<br />
        スタッフからの<br />
        証言をもとに<br />
        謎を解け
      </v-col>
      <v-col cols="12" v-if="viewQ1" class="text-center ma-0 pt-0 pb-0 pr-5 pl-5">
        <img src="/images/mission4_q_a.png" width="100%" />
      </v-col>
      <v-col cols="12" v-if="viewQ2" class="text-center ma-0 pt-3 pb-0 pr-5 pl-5">
        <img src="/images/mission4_q_yes.png" width="100%" />
      </v-col>
      <v-col cols="12" v-if="viewQ3" class="text-center ma-0 pt-0 pb-0 pr-5 pl-5">
        <img src="/images/mission4_q_b.png" width="100%" />
      </v-col>
      <v-col cols="12" v-if="viewQ4" class="text-center ma-0 pt-3 pb-0 pr-5 pl-5">
        <img src="/images/mission4_q_yes.png" width="100%" />
      </v-col>
      <v-col cols="12" v-if="viewQ5" class="text-center ma-0 pt-0 pb-0 pr-5 pl-5">
        <img src="/images/mission4_q_c.png" width="100%" />
      </v-col>
      <v-col cols="12" v-if="viewQ6" class="text-center ma-0 pt-3 pb-0 pr-5 pl-5">
        <img src="/images/mission4_q_yes.png" width="100%" />
      </v-col>
      <v-col cols="12" v-if="viewQ7" class="text-center ma-0 pt-0 pb-0 pr-5 pl-5">
        <img src="/images/mission4_q_d.png" width="100%" />
      </v-col>
      <v-col cols="12" v-if="viewQ8" class="text-center ma-0 pt-3 pb-0 pr-5 pl-5">
        <img src="/images/mission4_q_xxx.png" width="100%" />
      </v-col>

      <v-col cols="12" v-if="viewQ9 && nowMissionChapter <= 4 && nowMissionSection <= 1" class="text-center pt-10">
        <v-btn width="80%" class="btn_blue" @click="s1ViewInput">
          答える
        </v-btn>
      </v-col>
      <v-col cols="12" v-else-if="viewQ9">
        <v-row class="text-center">
          <v-col cols="12">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="75" height="42"
              viewBox="0 0 75 42">
              <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#1e4b69" />
                  <stop offset="1" stop-color="#4084a7" />
                </linearGradient>
              </defs>
              <g transform="translate(-42 -1507.996)">
                <rect width="75" height="42" transform="translate(42 1507.996)" fill="url(#linear-gradient)" />
                <text transform="translate(81 1536.996)" fill="#f5f2db" font-size="18"
                  font-family="ShipporiMinchoB1-Bold, Shippori Mincho B1" font-weight="700" letter-spacing="0.05em">
                  <tspan x="-18.45" y="0">答え</tspan>
                </text>
                <g transform="translate(48 1511.996)" fill="none" stroke="#fff" stroke-width="1">
                  <rect width="64" height="34" stroke="none" />
                  <rect x="0.5" y="0.5" width="63" height="33" fill="none" />
                </g>
              </g>
            </svg>

          </v-col>
          <v-col cols="12" class="text-center">
            <div class="answerArea">
              神野
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <InputArea v-show="viewMode == 2" uukey="m4s1" :answerTexts="['神野', 'じんの', '神野さん', 'じんのさん']"
      @action="s1ViewQuestion" @correct="s1AnswerOk">
      <template v-slot:question>
        苗字を入力せよ
      </template>
      <template v-slot:action>
        閉じる
      </template>
    </InputArea>

    <ViewKeyword v-if="viewMode == 3" class="ma-0" imagePath='/images/mission4_keyword.jpg' :start="true"
      @end="viewMode = 4">
    </ViewKeyword>


    <v-row class="text-center mt-5 mr-10" v-if="viewMode == 4">
      <v-col cols="12" class="ma-0 pa-0 ml-12 mr-5">
        <img src="/images/keyword_m1.jpg" />
      </v-col>
      <v-col cols="12" class="ma-0 pa-0 ml-0 mr-12">
        <img src="/images/keyword_m2.jpg" />
      </v-col>
      <v-col cols="12" class="ma-0 pa-0 ml-12 mr-5">
        <img src="/images/keyword_m3.jpg" />
      </v-col>
      <v-col cols="12" class="ma-0 pa-0 ml-0 mr-12">
        <img src="/images/keyword_m4.jpg" />
      </v-col>
    </v-row>
    <v-row class="text-center mb-15" v-if="viewMode == 4">
      <v-col cols="12" class="text-center pt-5">
        <v-btn width="80%" class="btn_blue" @click="viewMode = 5">
          次に進む
        </v-btn>
      </v-col>
    </v-row>

    <InputArea v-show="viewMode >= 5" uukey="m4s2" :answerTexts="['かんせん', '感染']" @action="viewMode = 4"
      @correct="s2AnswerOk">
      <template v-slot:question>
        4つのキーワードを<br />順に入力せよ
      </template>
      <template v-slot:action>
        閉じる
      </template>
    </InputArea>

    <div v-if="viewMode == 6" class="handArea">
      <img src="/images/mission4_hand_1.png" class="hand1" />
      <img src="/images/mission4_hand_2.png" class="hand2" />
      <img src="/images/mission4_hand_1.png" class="hand3" />
      <img src="/images/mission4_hand_2.png" class="hand4" />
    </div>

  </v-container>

</template>
<style scoped>
.qText {
  margin-top: 360px;
  margin-bottom: 50px;
  font-size: 10vw;
  font-weight: bold;
  color: #F5F2DB;
}

.handArea {
  position: absolute;
  top: 0;
  left: 0;
}

.hand1 {
  position: absolute;
  top: 70vh;
  left: 50vw;
  width: 45vw;
  opacity: 0;
  animation-delay: 0s;
  animation-name: handAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.hand2 {
  position: absolute;
  top: 45vh;
  left: -8vw;
  width: 55vw;
  opacity: 0;
  animation-delay: 0.6s;
  animation-name: handAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.hand3 {
  position: absolute;
  top: 25vh;
  left: 45vw;
  width: 55vw;
  opacity: 0;
  animation-delay: 0.9s;
  animation-name: handAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.hand4 {
  position: absolute;
  top: -5vh;
  left: 0vw;
  width: 65vw;
  opacity: 0;
  animation-delay: 1.1s;
  animation-name: handAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes handAnime {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
<script>
import InputArea from "./components/InputArea";
import ViewKeyword from "./components/ViewKeyword";

export default {
  components: {
    InputArea,
    ViewKeyword
  },
  data: () => ({
    viewMode: 1,
    viewQ1: true,
    viewQ2: false,
    viewQ3: false,
    viewQ4: false,
    viewQ5: false,
    viewQ6: false,
    viewQ7: false,
    viewQ8: false,
    viewQ9: false,
    viewQCount: 1
  }),
  created: function () {
    if (this.nowMissionChapter < 4) {
      this.$router.push('/');
    } else if (this.nowMissionChapter == 4 && this.nowMissionSection == 0) {
      this.$router.push('/map');
    } else if (this.nowMissionChapter > 4) {
      this.viewQ1 = true;
      this.viewQ2 = true;
      this.viewQ3 = true;
      this.viewQ4 = true;
      this.viewQ5 = true;
      this.viewQ6 = true;
      this.viewQ7 = true;
      this.viewQ8 = true;
      this.viewQ9 = true;
    } else {
      if (this.nowMissionSection == 2) {
        this.viewMode = 4;
      } else {
        this.viewMode = 1;
        setTimeout(() => {
          window.addEventListener('scroll', this.scrollEvent);
        }, 1000);
      }
    }

  },
  destroyed: function () {
    window.removeEventListener('scroll', this.scrollEvent);
  },
  methods: {

    goMission5() {
      this.$router.push('/mission5');
    },
    scrollEvent() {
      const bottomPos = document.body.scrollHeight - document.documentElement.clientHeight;
      if (this.viewQCount <= 1 && bottomPos - window.pageYOffset < 100) {
        this.s1ViewAnime();
      }
    },
    s1ViewQuestion() {
      this.viewMode = 1;
      setTimeout(() => {
        scrollTo(0, document.body.scrollHeight);
      }, 150);
    },
    s1ViewInput() {
      this.viewMode = 2;
    },
    s1AnswerOk() {
      this.$store.commit("setMissionSection", 2);
      this.viewMode = 3;
      scrollTo(0, 0);
    },
    s1ViewAnime() {
      this.viewQCount++;
      // console.log(this.viewQCount);
      eval("this.viewQ" + this.viewQCount + " = true;");
      setTimeout(() => {
        scrollTo(0, document.body.scrollHeight);
      }, 50);
      if (this.viewQCount < 9) {
        setTimeout(() => {
          this.s1ViewAnime();
        }, 1250);
      }
    },
    s2ViewQuestion() {
      this.viewMode = 4;
    },
    s2AnswerOk() {
      this.viewMode = 6;
      gtag('event', 'missionclear', { 'event_category': this.nowMission, 'event_label': undefined });
      this.$store.commit("setNextMission", 5);
      scrollTo(0, 0);
      setTimeout(() => {
        this.goMission5();
      }, 2000);
    },
  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {
  }
};
</script>
