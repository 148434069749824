import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        auth: {
            lineUserHashKey: null,
            nowMissionChapter: 0,
            nowMissionSection: 0,
            hintPoints: [],
            checkTos: false,
            recoverHintPointNum: 0,
            localUserHashKey: null
        },
        checkDevice: false,
        localStorageKey: "yunessun_sadako_202209",
        hashPrefix: "YunesSUN202209saDAKko",
        qrcode: {
        },
        isMaintenance: false,
    },
    getters: {
        nowMissionChapter: state => state.auth.nowMissionChapter,
        nowMissionSection: state => state.auth.nowMissionSection,
        qrcode: state => state.qrcode,
        isLoad: state => state.auth.localUserHashKey != null,
        isMaintenance: state => state.isMaintenance,

        isCheckTos: state => state.auth.checkTos,
        checkDevice: state => state.checkDevice,
        countHintPoint: (state) => {
            return 3 * (state.auth.recoverHintPointNum + 1) - state.auth.hintPoints.length;
        },
        hintPoints: state => state.auth.hintPoints,
        lineUserHashKey: state => state.auth.lineUserHashKey,
        localUserHashKey: state => state.auth.localUserHashKey,

        localStorageKey: state => state.localStorageKey,
        hashPrefix: state => state.hashPrefix,

        checkAuth: state => state.auth,

        useHintList: (state) => {
            return state.auth.hintPoints;
        }
    },
    mutations: {
        init(state, data) {
            if (!data || !data.auth) return;
            // console.log("INIT", data.auth);
            state.auth.nowMissionChapter = data.auth.nowMissionChapter;
            state.auth.nowMissionSection = data.auth.nowMissionSection;
            state.auth.hintPoints = data.auth.hintPoints;
            state.auth.checkTos = data.auth.checkTos;
            state.auth.recoverHintPointNum = data.auth.recoverHintPointNum;
        },
        setMaintenance(state, data) {
            state.isMaintenance = data;
        },

        checkDevice(state, data) {
            state.checkDevice = data;
        },
        setTos(state, data) {
            state.auth.checkTos = data;
        },
        setUserIds(state, data) {
            state.auth.lineUserHashKey = data.lineUserHashKey;
            state.auth.localUserHashKey = data.localUserHashKey;
        },
        setNextMission(state, newNumber) {
            state.auth.nowMissionChapter = newNumber;
            state.auth.nowMissionSection = 0;
        },
        setMissionSection(state, newNumber) {
            state.auth.nowMissionSection = newNumber;
        },
        useHintPoint(state, data) {
            // console.log("USE", data);
            if (state.auth.hintPoints.indexOf(data) == -1)
                state.auth.hintPoints.push(data);
        },
        recoverHintPoint(state, data) {
            if (data) {
                state.auth.recoverHintPointNum++;
            }
        },
    },
    actions: {

    }
})