<template>
    <v-row class="ma-0 pa-0 tvWin">
        <v-col cols="12" class="ma-0 pa-5 fadeOut tvpos" ref="anime1">
            <div class="glitch tvshadow" style="background-image: url(/images/mission_tv.jpg);">
                <div class="channel r"></div>
                <div class="channel g"></div>
                <div class="channel b"></div>
            </div>
        </v-col>
        <v-col cols="12" class="ma-0 pa-5 fadeIn tvpos" ref="anime2">
            <div class="glitch tvshadow" :style="imageStyle">
                <div class="channel r"></div>
                <div class="channel g"></div>
                <div class="channel b"></div>
            </div>
        </v-col>
        <v-col cols="12" class="ma-0 pa-4 tvpos">
            <img src="/images/mission_keyword_tv.png" width="100%" />
        </v-col>
    </v-row>
</template>
<style scoped>
.tvWin {
    max-width: 500px !important;
    height: 90vh !important;
    display: flex;
    align-items: center;
}

.tvshadow {
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 1);
}

.tvpos {
    position: absolute;
    max-width: 500px !important;
}

.fadeIn {
    opacity: 0;
    animation-name: fadein;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 3s;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeOut {
    animation-name: fadeout;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 3s;
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.glitch {
    background: #000 no-repeat center;
    background-size: 0;
    width: 100%;
    padding-top: 75%;
    position: relative;
    overflow: hidden;
}

.glitch::before,
.glitch::after,
.glitch .channel {
    background: inherit;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.glitch::before {
    animation: glitch-before 1.5s linear infinite alternate both;
    content: "";
}

@keyframes glitch-before {
    0% {
        clip-path: polygon(0% 55.5280433537%, 100% 55.5280433537%, 100% 62.3577654809%, 0% 62.3577654809%);
        transform: translate(-1.3455785338%, 0.0025278698%);
    }

    4% {
        clip-path: polygon(0% 3.8794492954%, 100% 3.8794492954%, 100% 7.9235890118%, 0% 7.9235890118%);
        transform: translate(-2.1621570514%, 0.022450871%);
    }

    8% {
        clip-path: polygon(0% 88.3305624145%, 100% 88.3305624145%, 100% 95.1190117825%, 0% 95.1190117825%);
        transform: translate(-6.1328997446%, 0.3720168352%);
    }

    12% {
        clip-path: polygon(0% 45.3647832723%, 100% 45.3647832723%, 100% 52.744642633%, 0% 52.744642633%);
        transform: translate(6.9193341342%, -0.4229461809%);
    }

    16% {
        clip-path: polygon(0% 88.4745207233%, 100% 88.4745207233%, 100% 93.9426363284%, 0% 93.9426363284%);
        transform: translate(-1.2795220671%, 0.2322297915%);
    }

    20% {
        clip-path: polygon(0% 12.5745708342%, 100% 12.5745708342%, 100% 18.2298890962%, 0% 18.2298890962%);
        transform: translate(1.6500744655%, 0.477825748%);
    }

    24% {
        clip-path: polygon(0% 64.8844046825%, 100% 64.8844046825%, 100% 71.7465096979%, 0% 71.7465096979%);
        transform: translate(-0.9449988692%, -0.3283553134%);
    }

    42% {
        clip-path: polygon(0% 71.3178624223%, 100% 71.3178624223%, 100% 73.6619750569%, 0% 73.6619750569%);
        transform: translate(-4.2211421686%, -0.2779629069%);
    }

    48% {
        clip-path: polygon(0% 30.4504786623%, 100% 30.4504786623%, 100% 34.8032036586%, 0% 34.8032036586%);
        transform: translate(3.5471605137%, -0.1433984354%);
    }

    54% {
        clip-path: polygon(0% 60.655199026%, 100% 60.655199026%, 100% 66.3681593883%, 0% 66.3681593883%);
        transform: translate(5.1419391095%, 0.2048330159%);
    }

    60%,
    100% {
        clip-path: none;
        transform: none;
    }
}

.glitch::after {
    animation: glitch-after 1.5s linear infinite alternate both;
    content: "";
}

@keyframes glitch-after {
    0% {
        clip-path: polygon(0% 30.9095911025%, 100% 30.9095911025%, 100% 37.0484622795%, 0% 37.0484622795%);
        transform: translate(-5.6871855201%, 0.0276794658%);
    }

    4% {
        clip-path: polygon(0% 64.7660254483%, 100% 64.7660254483%, 100% 72.7056887154%, 0% 72.7056887154%);
        transform: translate(-5.2015660882%, -0.1649637537%);
    }

    8% {
        clip-path: polygon(0% 30.7760758829%, 100% 30.7760758829%, 100% 34.6437782417%, 0% 34.6437782417%);
        transform: translate(4.8418422594%, -0.1207807073%);
    }

    12% {
        clip-path: polygon(0% 61.3723756138%, 100% 61.3723756138%, 100% 66.711426665%, 0% 66.711426665%);
        transform: translate(-5.8981095227%, 0.1617081108%);
    }

    16% {
        clip-path: polygon(0% 33.5271174665%, 100% 33.5271174665%, 100% 42.8323728694%, 0% 42.8323728694%);
        transform: translate(1.9750121606%, -0.1901247217%);
    }

    20% {
        clip-path: polygon(0% 17.5931870601%, 100% 17.5931870601%, 100% 20.584294102%, 0% 20.584294102%);
        transform: translate(-3.9170760852%, 0.064545531%);
    }

    24% {
        clip-path: polygon(0% 28.8558109189%, 100% 28.8558109189%, 100% 30.6802016306%, 0% 30.6802016306%);
        transform: translate(-1.0918461391%, -0.0030245749%);
    }

    42% {
        clip-path: polygon(0% 11.4627548787%, 100% 11.4627548787%, 100% 16.7134533714%, 0% 16.7134533714%);
        transform: translate(6.2326636001%, -0.1004548086%);
    }

    48% {
        clip-path: polygon(0% 33.6795957951%, 100% 33.6795957951%, 100% 43.6351449165%, 0% 43.6351449165%);
        transform: translate(-4.0713069464%, 0.1925156644%);
    }

    54% {
        clip-path: polygon(0% 17.4726899673%, 100% 17.4726899673%, 100% 23.2445833797%, 0% 23.2445833797%);
        transform: translate(4.0973271692%, -0.4624716865%);
    }

    60%,
    100% {
        clip-path: none;
        transform: none;
    }
}

.glitch .channel {
    mix-blend-mode: screen;
}

.glitch .channel::before {
    bottom: 0;
    content: "";
    display: block;
    mix-blend-mode: multiply;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.glitch .r {
    animation: rgb-shift-r 1.5s steps(1, jump-end) infinite alternate both;
}

@keyframes rgb-shift-r {
    0% {
        transform: translate(-1.143415353%, 0.3421866237%);
    }

    4% {
        transform: translate(1.7326717669%, -0.4810489129%);
    }

    8% {
        transform: translate(0.2276708571%, 0.0348236573%);
    }

    12% {
        transform: translate(1.6848415987%, 0.3977647456%);
    }

    16% {
        transform: translate(-0.743886295%, -0.0806324636%);
    }

    20% {
        transform: translate(0.1856726126%, 0.1054399583%);
    }

    24% {
        transform: translate(-1.3455170523%, -0.2025036816%);
    }

    42% {
        transform: translate(-0.684102919%, -0.195631464%);
    }

    48% {
        transform: translate(-1.1831530675%, -0.3101540463%);
    }

    54% {
        transform: translate(0.3163450079%, 0.0340826717%);
    }

    60%,
    100% {
        transform: none;
    }
}

.glitch .r::before {
    background: #f00;
}

.glitch .g {
    animation: rgb-shift-g 1.5s steps(1, jump-end) infinite alternate both;
}

@keyframes rgb-shift-g {
    0% {
        transform: translate(0.6871171024%, -0.0278806413%);
    }

    4% {
        transform: translate(-0.4501911698%, 0.419365995%);
    }

    8% {
        transform: translate(1.4931856841%, -0.3775845563%);
    }

    12% {
        transform: translate(-0.6281125116%, 0.0874439324%);
    }

    16% {
        transform: translate(1.2473259242%, 0.2194344856%);
    }

    20% {
        transform: translate(-1.9550554955%, 0.2470541028%);
    }

    24% {
        transform: translate(1.2558290614%, -0.3845561208%);
    }

    42% {
        transform: translate(0.9523207864%, 0.3844330623%);
    }

    48% {
        transform: translate(0.8699952699%, -0.3051622146%);
    }

    54% {
        transform: translate(-0.5217943921%, 0.0269670382%);
    }

    60%,
    100% {
        transform: none;
    }
}

.glitch .g::before {
    background: #0f0;
}

.glitch .b {
    animation: rgb-shift-b 1.5s steps(1, jump-end) infinite alternate both;
}

@keyframes rgb-shift-b {
    0% {
        transform: translate(-0.4210183442%, 0.0611093711%);
    }

    4% {
        transform: translate(0.7743818073%, 0.4852797697%);
    }

    8% {
        transform: translate(0.4927600962%, 0.4504054268%);
    }

    12% {
        transform: translate(-0.1473259435%, 0.47143489%);
    }

    16% {
        transform: translate(-1.5718457301%, -0.2375704493%);
    }

    20% {
        transform: translate(-1.816419445%, -0.1272691377%);
    }

    24% {
        transform: translate(0.0571212013%, -0.1512419556%);
    }

    42% {
        transform: translate(-1.3077521499%, -0.1504342349%);
    }

    48% {
        transform: translate(1.2884410159%, -0.2724452385%);
    }

    54% {
        transform: translate(-0.970368069%, -0.0710642233%);
    }

    60%,
    100% {
        transform: none;
    }
}

.glitch .b::before {
    background: #00f;
}
</style>
<script>
export default {
    data: () => ({
        isViewImage: false,
        hideCounter: 0,
        viewCounter: 0,
        eventTimer: null
    }),
    props: {
        imagePath: String,
        startEvent: Boolean
    },
    mounted: function () {
        console.log("KEY");
        clearTimeout(this.eventTimer);
        this.eventTimer = setTimeout(() => {
            this.animeEnd();
        }, 8000);
    },
    methods: {
        animeEnd() {
            clearTimeout(this.eventTimer);
            this.$emit('end');
        }
    },
    computed: {
        // nowMissionChapter() {
        //     return this.$store.getters.nowMissionChapter;
        // },
        // nowMissionSection() {
        //     return this.$store.getters.nowMissionSection;
        // },
        // nowMission() {
        //     return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
        // }
        imageStyle: function () {
            return "background-image: url(" + this.imagePath + ");";
        }
    },
    watch: {
        startEvent(newVal) {
            console.log("key start", newVal);
        }
    }
}
</script>