<template>
    <div class="inputWin">
        <v-row align-content="center" class="text-center pl-10 pr-10" v-if="!isAnswerError">

            <v-col cols="12" class="pa-0 pt-3">
                <v-col cols="12" class="mt-10 pt-10 mb-5 q_text">
                    <slot name="question"></slot>
                </v-col>
                <v-col cols="12" class="pa-0 mb-10">
                    <input type="text" class="text_area" v-model="inputText" placeholder="ここに文字を入力" />
                </v-col>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    viewBox="0 0 145 40" @click="answerCheck">
                    <defs>
                        <linearGradient :id="btnId1" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#f5f2db" />
                            <stop offset="1" stop-color="#b2c8d4" />
                        </linearGradient>
                    </defs>
                    <g transform="translate(-70.5 -551)">
                        <path d="M0,0,14.122,38.492H143V0Z" transform="translate(214 589.992) rotate(180)" stroke="#fff"
                            stroke-width="1" :fill="btnId1Url" />
                        <text transform="translate(136 578.5)" fill="#1e4b69" font-size="18"
                            font-family="ShipporiMinchoB1-Bold, Shippori Mincho B1" font-weight="700"
                            letter-spacing="0.05em">
                            <tspan x="-27.9" y="0">答える</tspan>
                        </text>
                    </g>
                </svg>


                <!-- <button width="100%" @click="answerCheck" class="btn_ans_left">答える</button> -->
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    viewBox="0 0 145 40" @click="actionBtn">
                    <defs>
                        <linearGradient :id="btnId2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                            <stop offset="0" stop-color="#1e4b69" />
                            <stop offset="1" stop-color="#4084a7" />
                        </linearGradient>
                    </defs>
                    <g transform="translate(-213.284 -551)">
                        <path d="M0,0,14.122,38.492H143V0Z" transform="translate(214 551.5)" stroke="#fff"
                            stroke-width="1" :fill="btnId2Url" />
                        <text transform="translate(292 578.5)" fill="#f5f2db" font-size="18"
                            font-family="ShipporiMinchoB1-Bold, Shippori Mincho B1" font-weight="700"
                            letter-spacing="0.05em" x="0" y="0" text-anchor="middle">
                            <slot name="action"></slot>
                        </text>
                    </g>
                </svg>


                <!-- <button width="100%" @click="actionBtn" class="btn_ans_right">
                    <slot name="action"></slot>
                </button> -->
            </v-col>
        </v-row>
        <v-row class="text-center pl-10 pr-10" v-else>
            <v-col cols="12" class="mt-10 pt-10 mb-5 r_text">
                答えが<br />
                間違っているようだ
            </v-col>
            <v-col cols="12" class="pt-5">
                <v-btn width="80%" @click="isAnswerError = false" class="btn_action_full">もう一度答える</v-btn>
            </v-col>
            <v-col cols="12" class="pt-0">
                <v-btn width="80%" @click="actionBtn" class="btn_other_full">
                    <slot name="action"></slot>
                </v-btn>
            </v-col>
        </v-row>
        <div class="correct" v-if="correctAnime">
            <v-img src="/images/correct.svg" width="70%"></v-img>
        </div>
    </div>
</template>
<style scoped>
.btn_action_full {
    background: linear-gradient(to bottom, #F5F2DB, 50%, #B2C8D4) !important;
    font-weight: bold !important;
    color: #1E4B69 !important;
    font-size: 16px !important;
    padding: 20px 30px !important;
    border-radius: 0%;
}

.btn_other_full {
    background: linear-gradient(to bottom, #1F4D6B, 50%, #4084A7) !important;
    font-weight: bold !important;
    color: #F5F2DB !important;
    font-size: 16px !important;
    padding: 20px 30px !important;
    border: 1px solid #FFFFFF;
    border-radius: 0%;
}

.text_area {
    background-color: white !important;
    width: 100%;
    height: 12vw;
    font-size: 5vw;
    padding: 10px;
    font-weight: bold;
}

.inputWin {
    height: 80vh !important;
    display: flex;
    align-items: center;
}

.q_text {
    font-size: 8vw;
    font-weight: bold;
    color: #F5F2DB;
}

.r_text {
    font-size: 7vw;
    font-weight: bold;
    color: #F5F2DB;
}

.correctBase {
    position: relative;
}

.correct {
    position: absolute;
    animation-name: correctfade;
    animation-duration: 1400ms;
    max-width: 500px;
    top: 25vh;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    opacity: 0;
}


.correct>* {
    margin: 25px auto;
}

@keyframes correctfade {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}
</style>
<script>
export default {
    data: () => ({
        isOpen: true,
        isAnswerError: false,
        inputText: "",
        correctAnime: false,
        eventTimer: null
    }),
    props: {
        answerTexts: Array,
        uukey: String
    },
    created: function () {
        clearTimeout(this.eventTimer);
    },
    methods: {
        answerCheck() {
            clearTimeout(this.eventTimer);
            if (this.answerTexts.indexOf(this.inputText) >= 0) {
                this.correctAnime = true;
                this.eventTimer = setTimeout(() => {
                    this.$emit('correct');
                    this.correctAnime = false;
                }, 1300);
            } else {
                gtag('event', 'miss', { 'event_category': this.nowMission, 'event_label': this.inputText });
                this.isAnswerError = true;
            }
        },
        actionBtn() {
            this.$emit('action');
            this.isAnswerError = false;
        }
    },
    computed: {
        nowMissionChapter() {
            return this.$store.getters.nowMissionChapter;
        },
        nowMissionSection() {
            return this.$store.getters.nowMissionSection;
        },
        nowMission() {
            return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
        },
        btnId1() {
            return "a" + this.uukey + "1";
        },
        btnId1Url() {
            return "url(#a" + this.uukey + "1)";
        },
        btnId2() {
            return "a" + this.uukey + "2";
        },
        btnId2Url() {
            return "url(#a" + this.uukey + "2)";
        }
    },
    watch: {
        isOpen(newVal) {
            if (!newVal) {
                this.$emit('close');
            }
        }
    }
}
</script>