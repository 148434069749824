<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="ma-0 pa-0">
        <img src="/images/top_main.jpg" width="100%">
      </v-col>
    </v-row>

    <v-row class="text-center ma-0 pa-0 topBtn">

      <v-col cols="12">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="80%"
          viewBox="0 0 293.417 57.134" @click="goMission">
          <defs>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
              <stop offset="0" stop-color="#ff009c" />
              <stop offset="1" />
            </linearGradient>
            <clipPath id="clip-path">
              <path
                d="M9.992,28.067a3.216,3.216,0,1,1,3.216,3.217,3.221,3.221,0,0,1-3.216-3.217m264,0a3.217,3.217,0,1,1,3.217,3.217,3.221,3.221,0,0,1-3.217-3.217M21.567.284.354,28.567,21.567,56.851H271.85L293.063,29.07,271.852.284Z"
                fill="url(#linear-gradient)" />
            </clipPath>
            <clipPath id="clip-path-2">
              <rect id="長方形_1271" data-name="長方形 1271" width="293.417" height="57.134" transform="translate(0 0)"
                fill="none" />
            </clipPath>
          </defs>
          <g transform="translate(-67 -805)">
            <g>
              <g>
                <g transform="translate(67 805)">
                  <g clip-path="url(#clip-path)">
                    <rect id="長方形_1270" data-name="長方形 1270" width="292.709" height="56.567"
                      transform="translate(0.354 0.284)" fill="url(#linear-gradient)" />
                  </g>
                </g>
                <g transform="translate(67 805)">
                  <g clip-path="url(#clip-path-2)">
                    <path
                      d="M271.709.567l21,28.5-21,27.5h-250l-21-28,21-28Zm5.5,31a3.5,3.5,0,1,0-3.5-3.5,3.5,3.5,0,0,0,3.5,3.5m-264,0a3.5,3.5,0,1,0-3.5-3.5,3.5,3.5,0,0,0,3.5,3.5M271.995,0H21.426l-.17.227-21,28L0,28.567l.256.34,21,28,.17.227H271.989l.171-.223,21-27.5.258-.338-.252-.342-21-28.5Zm5.214,31a2.933,2.933,0,1,1,2.933-2.933A2.937,2.937,0,0,1,277.209,31m-264,0a2.933,2.933,0,1,1,2.933-2.933A2.937,2.937,0,0,1,13.209,31"
                      fill="#d20080" />
                  </g>
                </g>
              </g>
            </g>
            <text id="謎解きを始める" transform="translate(214 841)" fill="#fff" font-size="18"
              font-family="ShipporiMinchoB1-Bold, Shippori Mincho B1" font-weight="700" letter-spacing="0.05em">
              <tspan x="-65.7" y="0" v-if="nowMissionChapter > 5">クリア特典を見る</tspan>
              <tspan x="-65.7" y="0" v-else>謎解きを始める</tspan>
            </text>
          </g>
        </svg>
      </v-col>
    </v-row>



    <v-row class="ma-3 mt-0 pt-0" max-width="200">
      <v-col cols="12" class="text-center" style="font-weight: bold;" @click="maintenanceCheck(1)">
        謎解きを<br />
        お楽しみいただくにあたって
      </v-col>
      <v-col cols="12" v-if="$store.getters.isMaintenance">
        <hr />
      </v-col>
      <v-col cols="12" style="font-size:0.8em;" @click="maintenanceCheck(2)">
        <ul>
          <li>LINEと連携して謎解きの進行を保存しています。必ずLINE内ブラウザを使用してお楽しみいただきますようお願いいたします。</li>
          <li>音声つきの動画が流れますので音量にご注意ください。</li>
          <li>クリア特典でカメラの利用が必要となりますので許諾をお願いいたします。</li>
          <li>本コンテンツご利用時のデータ通信量（パケット）はお客様負担となります。通信についての契約確認のうえでご利用ください。</li>
          <li>OSとLINEのバージョンは最新版を推奨しております。iPadでは参加できない場合がございます。</li>
          <li>これからお楽しみいただくお客様のために、謎の問題、解答やヒントをブログやSNSなど、インターネットで公開しないでください。遊んでみての感想は大歓迎です。</li>
          <li>歩きながらのスマートフォンの使用は危険です。スマートフォン等の操作は周囲の安全を確認し、止まってご利用ください。</li>
        </ul>
      </v-col>
    </v-row>
    <v-row class="text-center mt-12 mb-12">
      <v-col cols="12">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="80%"
          viewBox="0 0 293.417 57.134" @click="goMission">
          <defs>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
              <stop offset="0" stop-color="#ff009c" />
              <stop offset="1" />
            </linearGradient>
            <clipPath id="clip-path">
              <path
                d="M9.992,28.067a3.216,3.216,0,1,1,3.216,3.217,3.221,3.221,0,0,1-3.216-3.217m264,0a3.217,3.217,0,1,1,3.217,3.217,3.221,3.221,0,0,1-3.217-3.217M21.567.284.354,28.567,21.567,56.851H271.85L293.063,29.07,271.852.284Z"
                fill="url(#linear-gradient)" />
            </clipPath>
            <clipPath id="clip-path-2">
              <rect id="長方形_1271" data-name="長方形 1271" width="293.417" height="57.134" transform="translate(0 0)"
                fill="none" />
            </clipPath>
          </defs>
          <g transform="translate(-67 -805)">
            <g>
              <g>
                <g transform="translate(67 805)">
                  <g clip-path="url(#clip-path)">
                    <rect id="長方形_1270" data-name="長方形 1270" width="292.709" height="56.567"
                      transform="translate(0.354 0.284)" fill="url(#linear-gradient)" />
                  </g>
                </g>
                <g transform="translate(67 805)">
                  <g clip-path="url(#clip-path-2)">
                    <path
                      d="M271.709.567l21,28.5-21,27.5h-250l-21-28,21-28Zm5.5,31a3.5,3.5,0,1,0-3.5-3.5,3.5,3.5,0,0,0,3.5,3.5m-264,0a3.5,3.5,0,1,0-3.5-3.5,3.5,3.5,0,0,0,3.5,3.5M271.995,0H21.426l-.17.227-21,28L0,28.567l.256.34,21,28,.17.227H271.989l.171-.223,21-27.5.258-.338-.252-.342-21-28.5Zm5.214,31a2.933,2.933,0,1,1,2.933-2.933A2.937,2.937,0,0,1,277.209,31m-264,0a2.933,2.933,0,1,1,2.933-2.933A2.937,2.937,0,0,1,13.209,31"
                      fill="#d20080" />
                  </g>
                </g>
              </g>
            </g>
            <text transform="translate(214 841)" fill="#fff" font-size="18"
              font-family="ShipporiMinchoB1-Bold, Shippori Mincho B1" font-weight="700" letter-spacing="0.05em">
              <tspan x="-65.7" y="0" v-if="nowMissionChapter > 5">クリア特典を見る</tspan>
              <tspan x="-65.7" y="0" v-else>謎解きを始める</tspan>
            </text>
          </g>
        </svg>
      </v-col>
    </v-row>
    <v-row class="text-center mt-12 mb-12">
      <v-col cols="12">
        <v-img src="/images/banner_movie.jpg" @click="goLink('https://movies.kadokawa.co.jp/sadako-movie/')"></v-img>
      </v-col>
      <v-col cols="12">
        <v-img src="/images/banner_yu.jpg" @click="goLink('https://www.yunessun.com/')"></v-img>
      </v-col>
      <v-col cols="12" class="text-center">
        <img src="/images/top_movie.png" width="70%" />
      </v-col>
    </v-row>
    <!-- <div class="text-right topCoupon pb-3 pr-3" v-if="nowMissionChapter < 2">
      <img src="/images/top_coupon.png" />
    </div> -->

  </v-container>
</template>
<style scoped>
.topcomment {
  font-family: 'Shippori Mincho', serif;
}

.topBtn {
  position: relative;
  top: -450px;
}

/* .topCoupon {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
} */
</style>
<script>
import Mission5Change from "./components/Mission5Change";

export default {
  name: "Top",
  data: () => ({
    dialogGoMission: false,
    checkPermission: false,
    permissonError: false,
    liffError: false,
    liffProfile: null,
    maintenanceCount: 0
  }),
  components: {
    Mission5Change
  },

  created: function () {
    if (this.nowMissionChapter < 1) {
      this.$router.push('/');
    }
    this.maintenanceCount = 0;
  },
  destroyed: function () {
  },
  methods: {
    goMission() {
      if (this.nowMissionChapter == 1 && this.nowMissionSection == 0) {
        this.$router.push('/missioninfo');
      } else if (this.nowMissionChapter > 5) {
        this.$router.push('/present');
      } else if (this.nowMissionSection == 0 && this.nowMissionChapter < 5) {
        this.$router.push('/map');
      } else {
        this.$router.push('/mission' + this.nowMissionChapter);
      }

    },
    goLink(url) {
      if (liff) {
        liff.openWindow({ "url": url, "external": true });
      }
    },
    async permissionOk() {
      this.$store.commit("checkDevice", true);
      this.$store.commit("setTos", true);

      try {
        this.checkPermission = true;
        this.permissonError = false;
        try {
          await this.$stamp.checkUseDevice();
        } catch (error) {
          this.$router.push({ name: 'error', params: { 'errormessage': "カメラなどのデバイスの利用ができません。" } });
        }
        this.checkPermission = false;
        this.dialogTos = false;

      } catch (e) {
        this.checkPermission = false;
        this.permissonError = true;
        console.error(e);
      }
    },
    // deleteUser() {
    //   const delData = {
    //     lineUserHashKey: this.$store.getters.lineUserHashKey,
    //     "deletePassKey": "yunessan delete abcde1234",
    //     localUserHashKey: this.$store.getters.localUserHashKey
    //   }
    //   localStorage.removeItem(this.$store.getters.localStorageKey);
    //   localStorage.removeItem(this.$store.getters.localStorageKey + "_key");
    //   axios.delete(
    //     'https://9o49cj3vk3.execute-api.ap-northeast-1.amazonaws.com/prod/user',
    //     { data: delData })
    //     .then((res) => {
    //       // console.log("☆ServerRes", res);
    //       location.reload(true);
    //     })
    //     .catch((error) => {
    //       console.error("☆☆ServerERROR", error);
    //     });
    //   //https://9o49cj3vk3.execute-api.ap-northeast-1.amazonaws.com/prod/user
    // }
    maintenanceCheck(num) {
      if (num == 0) {
        this.maintenanceCount = 0;
      } else if (num == 1 && this.maintenanceCount % 4 < 2) {
        this.maintenanceCount++;
      } else if (num == 2 && this.maintenanceCount % 4 >= 2) {
        this.maintenanceCount++;
      } else {
        this.maintenanceCount = 0;
      }
      // console.log(num, this.maintenanceCount, this.maintenanceCount % 4)
      if (this.maintenanceCount == 6) {
        console.log("maintenance mode on");
        this.$store.commit("setMaintenance", true);
        this.maintenanceCount = 0;
      }

    }
  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {

  }
};
</script>
