<template>
  <v-container>
    <v-row class="text-center mb-15" v-if="viewMode == 1">
      <v-col cols="12" class="text-center" style="margin-top:80vh;margin-bottom: 20vh;">
        <img src="/images/scrollArrow.svg" />
      </v-col>
      <v-col cols="12" class="text-center ma-0 pt-0" style="padding-bottom:60vh;" ref="hand">
        <img src="/images/mission5_hand.png" width="100%" />
      </v-col>
      <v-col cols="12" class="text-center ma-0 mb-15" style="padding-top: 30vh;" ref="sada">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
          viewBox="0 0 420 800">
          <g ref="sadaAnime">
            <image href="/images/mission5_sada.png" width="600" x="-120" y="10" class="viewSada" />
            <image href="/images/mission5_nazo.png" width="280" x="70" y="400" class="viewTitle" />
          </g>
        </svg>
      </v-col>

      <v-col cols="12" class="text-center qText">
        赤ではなく、<br />
        青を読め<br />
        <br />
        「かんせん」を<br />
        この答えの通り<br />
        変換せよ
      </v-col>
      <v-col cols="12" class="text-center ma-0 pa-0 mb-10">
        <img src="/images/mission3_q.jpg" width="80%" />
      </v-col>
      <v-col cols="12" v-if="nowMissionChapter <= 5 && nowMissionSection <= 1">
        <v-btn width="80%" class="btn_blue" @click="s1ViewInput">
          答える
        </v-btn>
      </v-col>
      <v-col cols="12" v-else>
        <v-row class="text-center">
          <v-col cols="12">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="75" height="42"
              viewBox="0 0 75 42">
              <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#1e4b69" />
                  <stop offset="1" stop-color="#4084a7" />
                </linearGradient>
              </defs>
              <g transform="translate(-42 -1507.996)">
                <rect width="75" height="42" transform="translate(42 1507.996)" fill="url(#linear-gradient)" />
                <text transform="translate(81 1536.996)" fill="#f5f2db" font-size="18"
                  font-family="ShipporiMinchoB1-Bold, Shippori Mincho B1" font-weight="700" letter-spacing="0.05em">
                  <tspan x="-18.45" y="0">答え</tspan>
                </text>
                <g transform="translate(48 1511.996)" fill="none" stroke="#fff" stroke-width="1">
                  <rect width="64" height="34" stroke="none" />
                  <rect x="0.5" y="0.5" width="63" height="33" fill="none" />
                </g>
              </g>
            </svg>

          </v-col>
          <v-col cols="12" class="text-center">
            <div class="answerArea">
              おんせん
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <InputArea v-show="viewMode == 2" :answerTexts="['おんせん', '温泉']" @action="s1ViewQuestion" @correct="s1AnswerOk">
      <template v-slot:question>
        答えを入力せよ
      </template>
      <template v-slot:action>
        閉じる
      </template>
    </InputArea>

    <v-row class="text-center mt-5" v-if="viewMode == 3">
      <v-col cols="12" class="ma-0 pa-0 ">
        <Mission5Change width="100%" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="ma-0 pa-0" v-show="viewMode == 4">
        <img src="/images/mission5_clear.jpg" width="100%">
      </v-col>
    </v-row>

  </v-container>

</template>
<style scoped>
.qText {
  margin-top: 360px;
  margin-bottom: 50px;
  font-size: 10vw;
  font-weight: bold;
  color: #F5F2DB;
}

.viewSada {
  opacity: 0;
}

.viewAnime>.viewSada {
  opacity: 0;
  animation-name: sadaAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  transform-origin: center;
}

@keyframes sadaAnime {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  60% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.viewTitle {
  opacity: 0;
}

.viewAnime>.viewTitle {
  opacity: 0;
  animation-name: titleAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
  transform-origin: center;
}

@keyframes titleAnime {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0)
  }
}
</style>
<script>
import InputArea from "./components/InputArea";
import Mission5Change from "./components/Mission5Change";

export default {
  components: {
    InputArea,
    Mission5Change
  },
  data: () => ({
    viewMode: 1,
    isActionSada: false
  }),
  created: function () {
    if (this.nowMissionChapter < 5) {
      this.$router.push('/');
    }
  },
  mounted: function () {
    document.addEventListener("scroll", this.pageScroll);
  },
  destroyed: function () {
    document.removeEventListener("scroll", this.pageScroll);
  },
  methods: {

    goMap() {
      this.$router.push('/map');
    },
    s1ViewQuestion() {
      this.viewMode = 1;
      setTimeout(() => {
        scrollTo(0, document.body.scrollHeight);
      }, 150);
    },
    s1ViewInput() {
      this.viewMode = 2;
    },
    s1AnswerOk() {
      this.viewMode = 3;
      gtag('event', 'missionclear', { 'event_category': this.nowMission, 'event_label': undefined });
      this.$store.commit("setNextMission", 6);
      scrollTo(0, 0);
      setTimeout(() => {
        this.viewMode = 4;
        setTimeout(() => {
          this.$router.push('/present');
        }, 4000);
      }, 7000);
    },
    pageScroll() {
      // console.log("S", this.isActionSada, this.$refs.hand.getBoundingClientRect().bottom, this.$refs.sada.getBoundingClientRect().bottom);
      if (!this.isActionSada && this.$refs.hand && this.$refs.hand.getBoundingClientRect().bottom < 0) {
        this.isActionSada = true;
        this.$refs.sadaAnime.classList.add("viewAnime");
      }
    }

  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {
  }
};
</script>
