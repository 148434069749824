<template>
  <v-container>
    <div class="text-center pt-5">
      <br />
      <br />
      <div>読み込み中</div>
      <br />
      <div class="hollow-dots-spinner" style="margin:0 auto">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  </v-container>
</template>
<style scoped>
.hollow-dots-spinner,
.hollow-dots-spinner * {
  box-sizing: border-box;
}

.hollow-dots-spinner {
  height: 15px;
  width: calc(30px * 3);
}

.hollow-dots-spinner .dot {
  width: 15px;
  height: 15px;
  margin: 0 calc(15px / 2);
  border: calc(15px / 5) solid #999999;
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
  animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
  animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
  animation-delay: calc(300ms * 3);

}

@keyframes hollow-dots-spinner-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
<script>
import axios from "axios";

export default {
  created: async function () {
    let localHashKey;
    let lineHashKey;
    let isNewUser = false;
    try {
      localHashKey = localStorage.getItem(this.$store.getters.localStorageKey + "_key");
      if (!localHashKey) throw new Error("not localHashKey");
    } catch (error) {
      let ukey = this.$store.getters.hashPrefix + Math.floor(Math.random() * 100000) + "_" + (new Date()).getTime() + "_" + navigator.userAgent;
      localHashKey = this.$CryptoJS.SHA256(ukey).toString();
      localStorage.setItem(this.$store.getters.localStorageKey + "_key", localHashKey);
      isNewUser = true;
    }

    try {
      // LIFFの初期化
      await liff.init({
        liffId: process.env.VUE_APP_LINE_LIFF_ID,
      });

      if (!liff.isInClient()) {
        throw new Error("not line client");
      }
      if (!liff.isLoggedIn()) {
        throw new Error("not login");
      }

      let profile = await liff.getProfile();
      console.log("☆LINE profile", profile);

      lineHashKey = this.$CryptoJS.SHA256("sada2022" + profile.userId).toString();
      let res = await axios.get(process.env.VUE_APP_SERVER_SAVE_PATH + "user/line_" + lineHashKey);
      // let res = await axios.get("/demo/serverdata.json");
      console.log("☆SERVER LINE RES", res);

      if (res.error) throw new Error("server error " + res.message);

      this.$store.commit("init", res.data);
      gtag('event', 'device', { 'event_category': 'line', 'event_label': undefined });

    } catch (e) {
      gtag('event', 'device', { 'event_category': 'browser', 'event_label': undefined });
      console.log("☆LIFF ERROR", e, isNewUser);
      if (!isNewUser) {
        try {
          let res = await axios.get(rocess.env.VUE_APP_SERVER_SAVE_PATH + "user/local_" + localHashKey);
          // let res = await axios.get("/demo/serverdata.json");
          console.log("☆SERVER LOCAL RES", res);
          if (res.data.error) throw new Error("server error " + res.data.message);
          this.$store.commit("init", res.data);
        } catch (error) {

          console.log(error);
          let ldata = localStorage.getItem(this.$store.getters.localStorageKey);
          // console.log("---ldata", ldata);
          if (ldata) {
            try {
              let ldataObj = JSON.parse(ldata);
              if (ldataObj.localUserHashKey) {
                this.$store.commit("init", { "auth": ldataObj });
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    }

    this.$store.commit("setUserIds", {
      "lineUserHashKey": lineHashKey,
      "localUserHashKey": localHashKey
    });

    if (this.nowMissionChapter == 0) {
      this.$router.push('/intro');
    } else {
      this.$router.push('/top');
    }

  },
  methods: {

  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  }
}
</script>

