<template>
  <v-container>
    <v-row class="mt-0">
      <v-col cols="12" class="text-center" style="margin-top:80vh;margin-bottom: 20vh;">
        <img src="/images/scrollArrow.svg" />
      </v-col>

      <v-col cols="12" class="text-center mt-0 pt-0" style="margin-bottom:100vh;">
        <img src="/images/missionintro_1.jpg" ref="img1" width="100%" />
      </v-col>

      <v-col cols="12" class="text-center mt-0 pt-0" style="margin-bottom:80vh;" ref="semiTarget">
        <svg xmlns="http://www.w3.org/2000/svg" width="180" height="179" viewBox="-45 0 90 179">
          <text fill="#fff" font-size="20" font-family="ShipporiMincho-Bold, しっぽり明朝" font-weight="700">
            <tspan x="-90" y="22">先の映像を見た者は</tspan>
            <tspan x="-52.27" y="72">24時間後に</tspan>
            <tspan font-size="30">
              <tspan x="-85" y="122">謎の不審死</tspan>
              <tspan y="122" font-size="20">を</tspan>
            </tspan>
            <tspan x="-70" y="172">遂げるという。</tspan>
          </text>
        </svg>
      </v-col>


      <v-col cols="12" class="text-center ma-0 pa-0">
        <img src="/images/missionintro_semi.png" ref="semi" class="semiDef" />
      </v-col>

      <v-col cols="12" class="text-center mb-0 pa-0" style="margin-top:50vh;">
        <img src="/images/missionintro_2.jpg" ref="img2" width="100%" />
      </v-col>

      <v-col cols="12" class="btnArea text-center">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="80%"
          viewBox="0 0 293.417 57.134" @click="goMap">
          <defs>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
              <stop offset="0" stop-color="#ff009c" />
              <stop offset="1" />
            </linearGradient>
            <clipPath id="clip-path">
              <path
                d="M9.992,28.067a3.216,3.216,0,1,1,3.216,3.217,3.221,3.221,0,0,1-3.216-3.217m264,0a3.217,3.217,0,1,1,3.217,3.217,3.221,3.221,0,0,1-3.217-3.217M21.567.284.354,28.567,21.567,56.851H271.85L293.063,29.07,271.852.284Z"
                fill="url(#linear-gradient)" />
            </clipPath>
            <clipPath id="clip-path-2">
              <rect width="293.417" height="57.134" transform="translate(0 0)" fill="none" />
            </clipPath>
          </defs>
          <g transform="translate(-67 -805)">
            <g>
              <g>
                <g transform="translate(67 805)">
                  <g clip-path="url(#clip-path)">
                    <rect width="292.709" height="56.567" transform="translate(0.354 0.284)"
                      fill="url(#linear-gradient)" />
                  </g>
                </g>
                <g transform="translate(67 805)">
                  <g clip-path="url(#clip-path-2)">
                    <path
                      d="M271.709.567l21,28.5-21,27.5h-250l-21-28,21-28Zm5.5,31a3.5,3.5,0,1,0-3.5-3.5,3.5,3.5,0,0,0,3.5,3.5m-264,0a3.5,3.5,0,1,0-3.5-3.5,3.5,3.5,0,0,0,3.5,3.5M271.995,0H21.426l-.17.227-21,28L0,28.567l.256.34,21,28,.17.227H271.989l.171-.223,21-27.5.258-.338-.252-.342-21-28.5Zm5.214,31a2.933,2.933,0,1,1,2.933-2.933A2.937,2.937,0,0,1,277.209,31m-264,0a2.933,2.933,0,1,1,2.933-2.933A2.937,2.937,0,0,1,13.209,31"
                      fill="#d20080" />
                  </g>
                </g>
              </g>
            </g>
            <text transform="translate(214 841)" fill="#fff" font-size="18"
              font-family="ShipporiMinchoB1-Bold, Shippori Mincho B1" font-weight="700" letter-spacing="0.05em">
              <tspan x="-46.8" y="0">第１の謎へ</tspan>
            </text>
          </g>
        </svg>

      </v-col>
    </v-row>

  </v-container>
</template>
<style scoped>
.btnArea {
  position: relative;
  top: -400px;
}

.semiDef {
  opacity: 0;
}

.semiAnime {
  position: fixed;
  top: 0;
  left: 30vw;
  animation-name: semiin;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(.48, .04, .23, 1.03);
  animation-fill-mode: forwards;

}

@keyframes semiin {
  0% {
    top: 30vh;
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  40%,
  80% {
    top: 50vh;
    opacity: 1;
  }

  100% {
    top: 50vh;
    opacity: 0;
  }
}
</style>
<script>
export default {
  data: () => ({
    // isAutoScrollEnd: false
    isActionSemi: false
  }),
  mounted: function () {
    document.addEventListener("scroll", this.pageScroll);
  },
  destroyed: function () {
    document.removeEventListener("scroll", this.pageScroll);
  },
  methods: {
    goMap() {
      this.$store.commit("setMissionSection", 1);
      this.$router.push('/map');
    },
    pageScroll() {
      // console.log("S", this.isActionSemi, this.$refs.semiTarget.getBoundingClientRect().bottom);
      if (!this.isActionSemi && this.$refs.semiTarget.getBoundingClientRect().bottom < 0) {
        this.isActionSemi = true;
        this.$refs.semi.classList.add("semiAnime");
      }
    }

  },
};
</script>