<template>
  <v-container>
    <v-row class="text-center mb-15" v-if="viewMode == 1">
      <v-col cols="12" class="text-center" style="margin-top:80vh;margin-bottom: 20vh;">
        <img src="/images/scrollArrow.svg" />
      </v-col>
      <v-col cols="12" class="text-center ma-0 pa-0">
        <img src="/images/mission1_smartphone.png" width="100%" />
      </v-col>
      <v-col cols="12" class="text-center " style="margin-top:60vh;margin-bottom: 20vh;">
        <img src="/images/mission1_nazo.png" width="70%" />
      </v-col>
      <v-col cols="12" class="text-center qText">
        順番に読め
      </v-col>
      <v-col cols="12" class="text-center ma-0 pa-0 mb-10">
        <img src="/images/mission1_q.gif" width="80%" />
      </v-col>
      <v-col cols="12" v-if="nowMissionChapter <= 1 && nowMissionSection <= 1">
        <v-btn width="80%" class="btn_blue" @click="s1ViewInput">
          答える
        </v-btn>
      </v-col>
      <v-col cols="12" v-else>
        <v-row class="text-center">
          <v-col cols="12">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="75" height="42"
              viewBox="0 0 75 42">
              <defs>
                <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                  <stop offset="0" stop-color="#1e4b69" />
                  <stop offset="1" stop-color="#4084a7" />
                </linearGradient>
              </defs>
              <g transform="translate(-42 -1507.996)">
                <rect width="75" height="42" transform="translate(42 1507.996)" fill="url(#linear-gradient)" />
                <text transform="translate(81 1536.996)" fill="#f5f2db" font-size="18"
                  font-family="ShipporiMinchoB1-Bold, Shippori Mincho B1" font-weight="700" letter-spacing="0.05em">
                  <tspan x="-18.45" y="0">答え</tspan>
                </text>
                <g transform="translate(48 1511.996)" fill="none" stroke="#fff" stroke-width="1">
                  <rect width="64" height="34" stroke="none" />
                  <rect x="0.5" y="0.5" width="63" height="33" fill="none" />
                </g>
              </g>
            </svg>

          </v-col>
          <v-col cols="12" class="text-center">
            <div class="answerArea">
              まさゆめ
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <InputArea v-show="viewMode == 2" :answerTexts="['まさゆめ', '正夢']" @action="s1ViewQuestion" @correct="s1AnswerOk">
      <template v-slot:question>
        答えを入力せよ
      </template>
      <template v-slot:action>
        閉じる
      </template>
    </InputArea>

    <ViewKeyword v-if="viewMode == 3" class="ma-0" imagePath='/images/mission1_keyword.jpg' :start="true" @end="goMap">
    </ViewKeyword>

  </v-container>



</template>
<style scoped>
.qText {
  margin-top: 360px;
  margin-bottom: 50px;
  font-size: 10vw;
  font-weight: bold;
  color: #F5F2DB;
}
</style>
<script>
import InputArea from "./components/InputArea";
import ViewKeyword from "./components/ViewKeyword";

export default {
  components: {
    InputArea,
    ViewKeyword
  },
  data: () => ({
    viewMode: 1
  }),
  created: function () {
    if (this.nowMissionChapter < 1) {
      this.$router.push('/');
    } else if (this.nowMissionChapter == 1 && this.nowMissionSection == 0) {
      this.$router.push('/map');
    }

  },
  destroyed: function () {
  },
  methods: {

    goMap() {
      this.$router.push('/map');
    },
    s1ViewQuestion() {
      this.viewMode = 1;
      setTimeout(() => {
        scrollTo(0, document.body.scrollHeight);
      }, 150);
    },
    s1ViewInput() {
      this.viewMode = 2;
    },
    s1AnswerOk() {
      this.viewMode = 3;
      gtag('event', 'missionclear', { 'event_category': this.nowMission, 'event_label': undefined });
      this.$store.commit("setNextMission", 2);
    }

  },
  computed: {
    nowMissionChapter() {
      return this.$store.getters.nowMissionChapter;
    },
    nowMissionSection() {
      return this.$store.getters.nowMissionSection;
    },
    nowMission() {
      return this.$store.getters.nowMissionChapter + "-" + this.$store.getters.nowMissionSection;
    }
  },
  watch: {
  }
};
</script>
